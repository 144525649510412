<template>
  <v-card flat class="fill-height d-flex flex-column">
    <v-card-title class="pa-0">
      <v-tabs
        background-color="grey lighten-4"
        :disabled="table.loading || processing"
        mandatory
        slider-color="primary"
        v-model="tab"
        height="56"
      >
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#leave">
          <v-badge color="green" :value="table.leaves.length" :content="table.leaves.length">
            <v-icon size="18" left>tw-hr-travel</v-icon>
            {{ $t(`dashboard.request_type.leave`) }}
          </v-badge>
        </v-tab>
        <v-tab class="body-2 font-weight-bold text-capitalize" href="#overtime">
          <v-badge color="green" :value="table.overtimes.length" :content="table.overtimes.length">
            <v-icon size="18" left>tw-shifts</v-icon>
            {{ $t(`dashboard.request_type.overtime`) }}
          </v-badge>
        </v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text class="pa-0 flex-grow-1">
      <v-tabs-items v-model="tab" mandatory class="fill-height">
        <v-tab-item value="leave" class="fill-height">
          <template v-if="table.loading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="table.leaves.length"
              class="request-table"
              :items="table.leaves"
              :loading="table.loading"
              :no-data-text="table.loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="row.item.leaveRequest.employee" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{
                        (row.item.leaveRequest.employee.employment.branch || {}).name || "-"
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        (row.item.leaveRequest.employee.employment.unit || {}).name || "-"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.leaveRequest.leaveType.name }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{ getLeaveDays(row.item) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template v-if="isApprover(row.item)">
                      <v-btn
                        :loading="processing && row.item.id === processedId && !isApproveProcess"
                        :disabled="processing && row.item.id !== processedId"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickLeaveApproval(row.item, false)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.id === processedId && isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickLeaveApproval(row.item, true)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_leaves") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
        <v-tab-item value="overtime" class="fill-height">
          <template v-if="table.loading">
            <v-card class="elevation-0 fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate size="60" color="grey lighten-3" />
            </v-card>
          </template>
          <template v-else>
            <v-data-table
              v-if="table.overtimes.length"
              class="request-table"
              :items="table.overtimes"
              :loading="table.loading"
              :no-data-text="table.loading ? '' : $vuetify.noDataText"
              :footer-props="{ itemsPerPageOptions: [5] }"
              hide-default-header
            >
              <template #item="row">
                <tr>
                  <td>
                    <employee-profile :employee="row.item.overtime.employee" />
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">{{
                        (row.item.overtime.employee.employment.branch || {}).name || "-"
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        (row.item.overtime.employee.employment.unit || {}).name || "-"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td>
                    <v-list-item-content>
                      <v-list-item-title>{{ row.item.overtime.workplan.date | formattedDate }}</v-list-item-title>
                      <v-list-item-subtitle class="caption">{{
                        getOvertimeFormat(row.item.overtime.overtimeInMinutes)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </td>
                  <td class="text-no-wrap text-right">
                    <template v-if="isApprover(row.item)">
                      <v-btn
                        :loading="processing && row.item.id === processedId && !isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || isApproveProcess)"
                        color="primary"
                        class="mr-2"
                        x-small
                        depressed
                        fab
                        @click="onClickOvertimeApproval(row.item, false)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-btn
                        :loading="processing && row.item.id === processedId && isApproveProcess"
                        :disabled="processing && (row.item.id !== processedId || !isApproveProcess)"
                        color="success"
                        x-small
                        depressed
                        fab
                        @click="onClickOvertimeApproval(row.item, true)"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <template v-else>
              <v-card class="elevation-0 fill-height d-flex justify-center align-center">
                {{ $t("dashboard.messages.no_data_overtimes") }}
              </v-card>
            </template>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>
<script>
  import { DASHBOARD_REQUEST } from "../query";

  export default {
    name: "requests",
    data: vm => ({
      tab: "leave",
      pendingRequestCount: 0,
      processedId: null,
      isApproveProcess: false,
      processing: false,
      table: {
        criteria: {
          manager: null,
          beginDate: vm.$moment().format("YYYY-MM-DD"),
          endDate: vm.$moment().format("YYYY-MM-DD")
        },
        loading: false,
        leaves: [],
        overtimes: [],
        headers: [],
        totalItems: 0,
        pagination: {
          rowsPerPage: 5
        }
      }
    }),

    methods: {
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: DASHBOARD_REQUEST,
            variables: this.table.criteria
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              const { leaveRequests, overtimeRequests } = data.dashboard;
              this.table.leaves = leaveRequests.filter(
                request => request.approver.id === this.$store.state.auth.user.id
              );
              this.table.overtimes = overtimeRequests.filter(
                request => request.approver.id === this.$store.state.auth.user.id
              );
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.table.loading = false;
          });
      },

      getLeaveDays(item) {
        const { leaveRequest } = item;
        const isHourly = leaveRequest.leaveRequestDays.find(item => item.duration === "HOURLY");
        if (isHourly) {
          let lengthInMinutes = leaveRequest.leaveRequestDays.reduce((acc, item) => (acc += item.lengthInMinutes));
          if (lengthInMinutes) {
            return lengthInMinutes.lengthInMinutes / 60 >= 1
              ? (lengthInMinutes.lengthInMinutes / 60).toFixed(2) + " " + this.$t("date.time")
              : lengthInMinutes.lengthInMinutes + " " + this.$t("date.minute");
          }
        } else {
          return leaveRequest.leaveRequestDays.length + " " + this.$t("date.day");
        }
      },
      isApprover(item) {
        if (item.approver) {
          return item.approver.id === this.$store.state.auth.user.id;
        } else {
          return false;
        }
      },
      onClickLeaveApproval({ id }, status) {
        if (id) {
          this.processedId = id;
          this.isApproveProcess = status;
          const payload = {
            approved: status,
            note: ""
          };
          this.processing = true;
          this.$api.leaveRequestService
            .approve(id, payload)
            .then(({ data }) => {
              if (!data.error) {
                this.fetchItems();
              }
            })
            .catch(e => {
              this.$helpers.showNotification(e.message);
            })
            .finally(() => {
              this.processing = false;
              this.processedId = null;
            });
        }
      },
      onClickOvertimeApproval({ id }, status) {
        if (id) {
          this.processedId = id;
          this.isApproveProcess = status;
          const payload = {
            approved: status,
            note: ""
          };
          this.processing = true;
          this.$api.overtimeService
            .approve(id, payload)
            .then(({ data }) => {
              if (!data.error) {
                this.fetchItems();
              }
            })
            .catch(e => {
              this.$helpers.showNotification(e.message);
            })
            .finally(() => {
              this.processing = false;
              this.processedId = null;
            });
        }
      },
      getOvertimeFormat(overtime) {
        return overtime / 60 >= 1
          ? (overtime / 60).toFixed(1) + " " + this.$t("date.time")
          : overtime.toFixed(1) + " " + this.$t("date.minute");
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped lang="scss">
  .request-table::v-deep {
    .v-data-table__wrapper {
      min-height: 325px;
    }
  }
</style>
